import { lazy } from 'react'
import { Navigate, Route } from 'react-router-dom'

import Routes from 'components/Routes/Routes'

const Dashboard = lazy(() => import('pages/team_lead/dashboard'))

//------- Roles -------
const Roles = lazy(() => import('pages/team_lead/roles'))
const Affiliates = lazy(() => import('components/Admin/Roles/Affiliates'))
const AddAffiliate = lazy(() => import('pages/admin/roles/addAffiliate'))
const Advertisers = lazy(() => import('components/Admin/Roles/Advertisers'))
const AddAdvertiser = lazy(() => import('pages/admin/roles/addAdvertiser'))
const Managers = lazy(() => import('components/Admin/Roles/Managers'))
const AddManagers = lazy(() => import('pages/admin/roles/addManagers'))
//------------------------------

//------- Distribution -------
const Distribution = lazy(() => import('pages/admin/distribution/distribution'))
const ReDistribution = lazy(() => import('components/Admin/ColdApi/ReDistribution'))
const DistributionList = lazy(() => import('pages/admin/distribution/distributionList'))
const DistributionRules = lazy(() => import('components/Admin/ColdApi/DistributionRules'))

const LeadList = lazy(() => import('components/Admin/ColdApi/LeadList'))

const DistributionLeadListDetails = lazy(() => import('pages/admin/distribution/distributionLeadListDetails'))
const LeadListUserSessions = lazy(() => import('components/Admin/ColdApi/LeadList/Details/UserSession'))
const LeadListConversion = lazy(() => import('components/Admin/ColdApi/LeadList/Details/Conversion'))

const DistributionLeadListAdd = lazy(() => import('pages/admin/distribution/distributionLeadListAdd'))
//------------------------------

//------- Statistic -------
const Statistic = lazy(() => import('pages/admin/statistic'))
//------- Api -------
const GeneralApi = lazy(() => import('components/Admin/Statistic/Api/Genaral/General'))
const СlicksApi = lazy(() => import('components/Admin/Statistic/Api/Сlicks/Сlicks'))
const UserSessionApi = lazy(() => import('components/Admin/Statistic/Api/UserSession/UserSession'))
const ConversionApi = lazy(() => import('components/Admin/Statistic/Api/Conversion/Conversion'))
//------------------------------
//------- S2s -------
const GeneralS2s = lazy(() => import('components/Admin/Statistic/S2s/Genaral/General'))
const СlicksS2s = lazy(() => import('components/Admin/Statistic/S2s/Сlicks/Сlicks'))
const ConversionS2s = lazy(() => import('components/Admin/Statistic/S2s/Conversion/Conversion'))
//------------------------------

const TeamLeadRoutes = (
  <>
    <Route path={Routes.dashboard.index} element={<Dashboard />} />

    <Route path={Routes.coldApi.index} element={<Distribution />}>
      <Route index element={<Navigate to={Routes.coldApi.reColdApi.index} />} />

      <Route path={Routes.coldApi.reColdApi.index} element={<ReDistribution />} />
      <Route path={Routes.coldApi.rules} element={<DistributionRules />} />
      <Route path={Routes.coldApi.leadList.index} element={<LeadList />} />
    </Route>
    <Route path={Routes.coldApi.reColdApi.list} element={<DistributionList />} />
    <Route path={Routes.coldApi.leadList.details.index} element={<DistributionLeadListDetails />}>
      <Route index element={<Navigate to={Routes.coldApi.leadList.details.userSession} />} />

      <Route path={Routes.coldApi.leadList.details.userSession} element={<LeadListUserSessions />} />
      <Route path={Routes.coldApi.leadList.details.conversion} element={<LeadListConversion />} />
    </Route>

    <Route path={Routes.coldApi.leadList.add.index} element={<DistributionLeadListAdd />}>
      <Route index element={<Navigate to={Routes.coldApi.leadList.add.userSession} />} />

      <Route path={Routes.coldApi.leadList.add.userSession} element={<LeadListUserSessions />} />
      <Route path={Routes.coldApi.leadList.add.conversion} element={<LeadListConversion />} />
    </Route>

    <Route path={Routes.roles.index} element={<Roles />}>
      <Route index element={<Navigate to={Routes.roles.affiliate.index} />} />

      <Route path={Routes.roles.affiliate.index} element={<Affiliates />} />
      <Route path={Routes.roles.advertiser.index} element={<Advertisers />} />
      <Route path={Routes.roles.manager.index} element={<Managers />} />
    </Route>

    <Route path={Routes.roles.affiliate.create} element={<AddAffiliate key={'affiliate-create'} />} />
    <Route path={Routes.roles.affiliate.edit} element={<AddAffiliate key={'affiliate-create'} />} />
    <Route path={Routes.roles.advertiser.create} element={<AddAdvertiser key={'advert-create'} />} />
    <Route path={Routes.roles.advertiser.edit} element={<AddAdvertiser key={'advert-create'} />} />
    <Route path={Routes.roles.manager.create} element={<AddManagers key={'add-manager'} />} />
    <Route path={Routes.roles.manager.edit} element={<AddManagers key={'edit-manager'} />} />

    <Route path={Routes.statistic.index} element={<Statistic />}>
      <Route index element={<Navigate to={Routes.statistic.directLink.general} />} />

      <Route path={Routes.statistic.directLink.index}>
        <Route index element={<Navigate to={Routes.statistic.directLink.general} />} />

        <Route path={Routes.statistic.directLink.general} element={<GeneralS2s />} />
        <Route path={Routes.statistic.directLink.clicks} element={<СlicksS2s />} />
        <Route path={Routes.statistic.directLink.conversion.index} element={<ConversionS2s />} />
      </Route>

      <Route path={Routes.statistic.api.index}>
        <Route index element={<Navigate to={Routes.statistic.api.general} />} />

        <Route path={Routes.statistic.api.general} element={<GeneralApi />} />
        <Route path={Routes.statistic.api.clicks} element={<СlicksApi />} />
        <Route path={Routes.statistic.api.userSession} element={<UserSessionApi />} />
        <Route path={Routes.statistic.api.conversion.index} element={<ConversionApi />} />
      </Route>
    </Route>
  </>
)

export default TeamLeadRoutes
