import axios from 'axios'
import * as Sentry from '@sentry/react'

import { store } from 'redux/store'
import { setLoading } from 'redux/loading-slice'
import { clear } from 'redux/auth/auth-slice'
import { clearNotifications } from 'redux/notificationsSlice'
import { errorNotify, notify } from 'components/common/utils/helpers'
import Cookies from 'components/common/utils/cookies'

export const instance = axios.create({
  baseURL: window._env_.REACT_APP_HOST,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'PXL-AUTHORIZATION': null
  }
})

//List of routes by which global error is processed
const ACCEPT_ROUTES = [
  // 'offer/v2',
  // 'affiliate/v1',
  // 'advertiser/v1',
  // 'manager/v1',
  // 'product/v1',
  // 'landing/v1',
  // 'setting',
  // 'distribution/v1',
  // 'relations',
  '/'
]

const checkRoutes = (currentUrl, accepsList) => {
  let isHasRoute = false

  for (let key of accepsList) {
    if (currentUrl.includes(key)) isHasRoute = true
  }

  return isHasRoute
}

let isRefreshing = null

instance.defaults.countResquests = 0

instance.interceptors.request.use(
  function(config) {
    instance.defaults.countResquests = instance.defaults.countResquests + 1
    if (instance.defaults.countResquests > 0) store.dispatch(setLoading(true))
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function(response) {
    //Any status code in the 2xx range will trigger this function
    if (response?.data?.status === 'error') {
      const url = response.config.url
      const isRouteForNotify = checkRoutes(url, ACCEPT_ROUTES)

      if (isRouteForNotify) errorNotify(response.data)
    }

    instance.defaults.countResquests = instance.defaults.countResquests - 1

    if (instance.defaults.countResquests < 1) store.dispatch(setLoading(false))

    return response
  },
  function(error) {
    instance.defaults.countResquests = instance.defaults.countResquests - 1
    if (instance.defaults.countResquests < 1) store.dispatch(setLoading(false))
    if (error.response?.status !== 500) Sentry.captureException(error)

    if (
      !isRefreshing &&
      // (error.response?.status === 401 || error.response?.status === 403)
      error.response?.status === 401
    ) {
      Cookies.removeAuth()
      store.dispatch(clear())
      store.dispatch(clearNotifications())
      window.location.replace('/login')
    }

    // if (error.response?.status !== 401)
    // if (!isRefreshing && error.response?.status === 401) {
    //   isRefreshing = axiosRefresh(Cookies.get('refreshToken'))
    //     .then((refreshResponse) => {
    //       // Обновление токена прошло успешно, теперь можно повторить исходный запрос с новым токеном
    //       const newAccessToken = refreshResponse.access
    //       Cookies.set('accessToken', newAccessToken)
    //       instance.defaults.headers.common[
    //         'Authorization'
    //       ] = `Bearer ${newAccessToken}`
    //       error.config.headers['Authorization'] = `Bearer ${newAccessToken}`
    //       return instance(error.config)
    //     })
    //     .catch((refreshError) => {
    //       // Ошибка при обновлении токена, нужно выполнить выход пользователя
    //       console.error('Token refresh failed:', refreshError)
    //       // Выполните действия по выходу пользователя или обработке ошибки обновления токена
    //       // Например, вы можете выставить какое-то флаговое значение и перенаправить пользователя на страницу входа
    //       return Promise.reject(refreshError)
    //     })
    //     .finally(() => {
    //       isRefreshing = null
    //     })
    // }


    const errorMessages = {
      400: '400: Bad request. Please check your input.',
      401: '401: Unauthorized. Please log in.',
      403: '403: Access forbidden. You don\'t have permission.',
      404: '404: Page not found. Please check the URL.',
      500: '500: Server error. Please contact support.',
      502: '502: Bad gateway. Please try again later.'
    }
    const message = errorMessages[error?.response?.status] || error.message

    notify(message)

    // Return a promise so that the request is repeated after the token is updated
    // Any status codes outside the 2xx range will trigger this function
    return isRefreshing ? isRefreshing : Promise.reject(error)
  }
)
