/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Cookies from 'components/common/utils/cookies'
import { useSelector } from 'react-redux'
import { axiosRefresh } from 'api/auth'

import Loader from 'components/common/Loader'
import Routes from 'components/Routes/Routes'

const PublicRoute = () => {
  const { isLogin } = useSelector((state) => state.auth)

  const token = Cookies.get(window._env_.REACT_APP_TOKEN_KEY)
  const userRole = Cookies.get(window._env_.REACT_APP_USER_ROLE_KEY)
  const isAccesInCookies = token && userRole

  useEffect(() => {
    if (!token) return
    axiosRefresh(token)
  }, [])

  if (isLogin || isAccesInCookies) {
    return <Navigate to={Routes.getUrl(Routes.dashboard.index)} />
  }

  return !isAccesInCookies ? <Outlet /> : <Loader />
}

export default PublicRoute
