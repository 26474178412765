import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from 'redux/store'
import { viewPortSize } from 'redux/info-slice'

import * as Sentry from '@sentry/react'

import App from './components/App'

import Routes from 'components/Routes/Routes'

import { StyleSheetManager } from 'styled-components'
import 'styles/index.css'

const isLocalhost = window.location.hostname === 'localhost'
if (!isLocalhost) {
  Sentry.init({
    dsn: 'https://1419597dc8ebe3f4863cdc359b703f4a@sentry.marksel.tech/4',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    debug: false,
    beforeSend(event, hint) {
      const error = hint?.originalException

      if (error && error.response) {
        const statusCode = error.response.status
        if (statusCode >= 400 && statusCode < 600) {
          return null
        }
      }

      return event
    }
  })
}


const MyComponent = () => {
  const navigate = useNavigate()

  const [hasError, setHasError] = useState(false)
  const [dataError, setDataError] = useState()

  const copyErrorToClipboard = () => {
    const errorText = JSON.stringify(dataError?.error, Object.getOwnPropertyNames(dataError?.error), 2)
    navigator.clipboard.writeText(errorText)
      .then(() => alert('Error details copied to clipboard!'))
      .catch(err => console.error('Failed to copy error:', err))
  }

  useEffect(() => {
    const handleError = (event) => {
      console.log('Error event:', event)
      setHasError(true)
      setDataError(event)
    }

    window.addEventListener('error', handleError)

    return () => {
      window.removeEventListener('error', handleError)
    }
  }, [])

  useEffect(() => {
    store.dispatch(viewPortSize(window.innerWidth))

    function handleResize() {
      store.dispatch(viewPortSize(window.innerWidth))
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (hasError) {
    return (
      <div
        className="wrap-error"
      >
        Something went wrong.
        <br />
        <p>Please contact support.</p>
        <br />

        <button onClick={copyErrorToClipboard}>
          <span>Copy</span> error details to send to support
        </button>

        <p>Click <span
          onClick={() => {
            navigate(Routes.getUrl(Routes.dashboard.index))
            setHasError(false)
          }}>here</span> to return to the main page.</p>
      </div>
    )
  }

  return <App />
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <StyleSheetManager shouldForwardProp={(prop) => true}>
        <MyComponent />
      </StyleSheetManager>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
)
